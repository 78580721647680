import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {l} from "../../library/locale";
import {routes} from "../../config/config";
import {getItemValues} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {formCreateOrUpdate} from "../../library/containers";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";
import {useTranslation} from "react-i18next";
import {setGraphItem} from "../../redux/reducers/GraphReducer";

const GraphEdit = () => {
    const {t} = useTranslation()
    const section = 'graph'
    const adminSection = 'graph'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, graph, integration, folder} = useSelector(store => store)
    const item = graph.item
    const project_item = project.item
    const integration_items = createObjectFromIntegrations(t, integration.list);

    // get form and set values
    const [form] = Form.useForm()
    const formFields = [
        {
            name: "title",
            type: "text",
            required: true,
            placeholder: true, // pass label
        },
        {
            name: "params.integration.id",
            type: "tags",
            data: 'integrations',
            values: integration_items,
        },
        {
            name: 'is_on',
            type: 'switcher',
            desc: null
        },
        {
            name: 'templates_only',
            type: 'switcher',
        },
        {
            name: "params.history_level",
            type: "menu",
            placeholder: t(section + '.form.params.history_level.value.blocks'),
            values: ['blocks', 'all', 'none'],
            localized: true
        },
        {
            name: "icon_name",
            section: 'common',
            placeholder: '',
            type: "icon"
        },
        {
            name: "folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
    ]

    // itis form state
    const [folderId, setFolderId] = useState(0) // for backToList url
    const [formValues, setFormValues] = useState({
        icon_name: 'square-terminal',
        folder_id: folder_id,
        params: {
            integration: {
                id: []
            },
            history_level: 'block'
        }
    })


    // get data from API first
    useLayoutEffect(() => {
        // set field values if correct data received
        if (admin.authorized && project_item.id) {
            dispatch({type: 'getGraphItem', admin, id});
        }
        if (admin.authorized && project_item.id && !integration.list.length) {
            dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id}});
        }

        return () => {
            dispatch(setGraphItem({}))
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    useEffect(() => {
        if (admin.authorized && project.item.id) {
            dispatch({type: 'getFolderList', admin, filters: {project_id: project.item.id, section: section}})
        }
        //eslint-disable-next-line
    }, [admin.authorized, project.item.id])


    // set field values to STATE if correct data received
    useEffect(() => {
        if (item) {
            let formValuesNew = getItemValues(item, formFields)
            if (item.section === 'template') formValuesNew.templates_only = true
            setFormValues(formValuesNew)

            if (formValuesNew.folder_id !== undefined) {
                setFolderId(formValuesNew.folder_id) // for backToList url
            }
        }
        //eslint-disable-next-line
    }, [item])

    // set field values to FORM if correct data received
    useEffect(() => {
        if (item.id === id && formValues.folder_id !== undefined) {
            form.setFieldsValue(formValues)
            setFolderId(formValues.folder_id) // for backToList url
        }

        return () => {
            form.resetFields()
        }
        //eslint-disable-next-line
    }, [item.id, formValues])

    // compose form functions
    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`);
    }
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        // console.log('onFinish values', values)
        formCreateOrUpdate('Graph', values, formFields, item.id, project_item, admin, dispatch)
        backToList(parseInt(values.folder_id))
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels generation
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default GraphEdit