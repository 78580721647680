import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Layout} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {l} from "../../library/locale";
import {routes} from "../../config/config";
import {deepGet, getItemValues, isMainProject} from "../../library/functions";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, dispatchItemDataWithFolders} from "../../library/containers";
import {SetItemEditFormOrPreloader} from "../../components/Form/ItemEditForm";
import {useTranslation} from "react-i18next";

const TemplateEdit = () => {
    const section = 'template'
    const adminSection = 'template'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)
    const project_id = Number(params.project_id)
    const {t} = useTranslation()

    // data from GET params
    const [searchParams] = useSearchParams();
    let folder_id = Number(searchParams.get('folder')) // for fast create in folder

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, template, folder} = useSelector(store => store)
    const project_item = project.item
    const item = template.item

    // itis form state
    const initFormValues = {
        // name: cryptoRandomString({length: 10}),
        // icon_name: 'marker',
        type: null,
        product_info: '',
        is_on: true,
        is_open: false,
        price: 0,
        folder_id: folder_id,
        params: {
            history_level: 'none'
        }
    }
    const [folderId, setFolderId] = useState(0)
    const [avatarUrl, setAvatarUrl] = useState('')
    const [coverUrl, setCoverUrl] = useState('')
    const [formValues, setFormValues] = useState(initFormValues)

    // get form and set values
    const [form] = Form.useForm()
    const isMainProj = isMainProject(project_id, project);
    const isCreated = !item.parent_id;
    let formFields = [
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'field',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            desc: '',
        },
        {
            name: "title",
            type: "text",
            placeholder: true,
            required: true,
        },
        {
            name: "folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: l('common.form.folder.desc'),
        },
        {
            label: l('common.form.is_on.label'),
            name: "is_on",
            type: "switcher",
        },
        {
            name: "is_open",
            type: "switcher",
            isVisible: isCreated,
        },
        {
            name: "is_public",
            type: "switcher",
            isVisible: isMainProj,
        },
        {
            name: 'params.subscription.is_on',
            type: "switcher",
            isVisible: isMainProj,
        },
        {
            name: "price",
            type: "number",
            isVisible: isMainProj,
        },
        {
            name: "params.subscription.period",
            type: "number",
            placeholder: '30',
            isVisible: isMainProj,
        },
        {
            name: "params.cover.url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: coverUrl,
            isVisible: isMainProj,
            desc: null,
            aspect: 1.8,
        },
        {
            type: "editor",
            name: "product_info",
            placeholder: true,
            form: form,
            formValues: formValues,
            isVisible: isCreated,
            extended: true,
        },
        {
            name: 'params.integration.show',
            type: "switcher",
            isVisible: isCreated,
        },
        {
            name: 'params.integration.single',
            type: "switcher",
            isVisible: isCreated,
        },
        {
            name: 'params.integration.required',
            type: "switcher",
            isVisible: isCreated,
        },
        {
            name: "params.history_level",
            type: "menu",
            values: ['none', 'blocks'],
            section: 'graph',
            placeholder: t('graph.form.params.history_level.value.none'),
            localized: true
        },
        {
            name: "type",
            type: "menu",
            values: [
                // 'autotunnel',
                'gamification',
                'automation',
                'integration',
                'tunnel',
                'other',
            ],
            isVisible: isCreated,
        },
        // {
        //     type: "textarea",
        //     name: "values",
        //     placeholder: true,
        // },
    ]

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemDataWithFolders(dispatch, admin, project_item, section, adminSection, id)
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id])

    // set field values to STATE if correct data received
    useEffect(() => {
        if (item && id && item.id === id) {
            let formValuesNew = getItemValues(item, formFields)
            if (formValuesNew.type === '') formValuesNew.type = null; // fix select value

            // convert json to string
            if (formValuesNew.value !== null) formValuesNew.value = JSON.stringify(formValuesNew.value)
            // if (typeof formValuesNew.value === 'string') {
            //     // replaced to backend
            //     formValuesNew.value = JSON.parse(formValuesNew.value)
            // }

            // save to form state
            setFormValues(formValuesNew)

            if (formValuesNew.folder_id !== undefined) {
                setFolderId(formValuesNew.folder_id) // for backToList url
            }
        }
        //eslint-disable-next-line
    }, [item])

    // set field values to FORM if correct data received
    useEffect(() => {
        // console.log('formValues', formValues)
        if (id && item.id === id) form.setFieldsValue(formValues)
        else form.setFieldsValue(initFormValues)

        setAvatarUrl(formValues.photo_url) // for upload field
        setCoverUrl(deepGet(formValues, 'params.cover.url')) // for upload field
        setFolderId(formValues.folder_id) // for backToList url
        //eslint-disable-next-line
    }, [item, formValues])

    // compose form functions
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        formCreateOrUpdate('Template', values, formFields, id, project_item, admin, dispatch)
        backToList(parseInt(values.folder_id))
    }

    const backToList = (folder_id = false) => {
        if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}?folder=${folder_id}`);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <SetItemEditFormOrPreloader
                    id={id}
                    item={item}
                    section={adminSection} // for labels generation
                    form={form}
                    formFields={formFields}
                    formValues={formValues}
                    onFinish={onFinish}
                    onFailed={onFailed}
                    backToList={backToList}
                />
            </Layout>
        </AppWrapper>
    )
}

export default TemplateEdit